import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import * as footerStyles from "./footer.module.css"

const partners_title="Partnerzy"
const contact_title="Kontakt"
const supervisor_title="Opiekun naukowy Koła"
const supervisor="dr hab. Tomasz Błachowicz"
const supervisor_email="Tomasz.Blachowicz@polsl.pl"
const chairman_title="Prezes Koła"
const chairman="Mateusz Rosa"
const chairman_email="mr310738@student.polsl.pl"
const contact_data_title="Dane kontaktowe"
const email_title="E-mail"
const email="skn.sat@polsl.pl"
const facebook_title="Facebook"
const facebook="facebook.com/silesianaerospacetechnologiespolsl"
const facebook_link = "https://www."+facebook
const address_title="Adres"
const address="Stanisława Konarskiego 22b 41-100 Gliwice"

const Footer = () => {
  const data = useStaticQuery(graphql`
  query {
    partners_logos: allFile(filter: {
        relativeDirectory: {eq: "logos-white"}, 
        extension: {regex: "/(png)/"}
      }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
  }
  `)

  return (
    <footer>
      <div className={footerStyles.partnersBackground}>
        <div className={footerStyles.innerPartners}>
          <div className={footerStyles.whiteLine}/>
          <h1>
            {partners_title}
          </h1>
          {data.partners_logos.edges.map((edge) => {
            return (
              <Img fixed={edge.node.childImageSharp.fixed}/>
            )
          })}
        </div>
      </div>
      <div className={footerStyles.contactBackground}>
        <div className={footerStyles.blackLine}/>
        <h1 className={footerStyles.contactTitle}>
          {contact_title}
        </h1>
        <div className={footerStyles.leftSide}>
          <div className={footerStyles.leftLine} />
          <div className={footerStyles.supervisorTitle}>
            {supervisor_title}
          </div>
          <div className={footerStyles.leftText}>
            <p>
              {supervisor}<br/>
              {supervisor_email}
            </p>
          </div>
          <div className={footerStyles.leftLine} />
          <div className={footerStyles.chairmanTitle}>
            {chairman_title}
          </div>
          <div className={footerStyles.leftText}>
            <p>
              {chairman}<br/>
              {chairman_email}
            </p>
          </div>
        </div>
        <div className={footerStyles.rightSide}>
          <div className={footerStyles.contactDataTitle}>
            {contact_data_title}
          </div>
          <div className={footerStyles.rightLine} />
          <div className={footerStyles.rightText}>
            <p>
              <b>{email_title}</b><br/>
              {email}
            </p>
            <p>
              <b>{facebook_title}</b><br/>
              <Link className={footerStyles.facebookLink}
                    to={facebook_link}>
                      {facebook}
              </Link>
            </p>
            <p>
              <b>{address_title}</b><br/>
              {address}
            </p>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer
